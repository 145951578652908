import { Company } from './Company';
import { WithCreatedUpdated } from './Default';
import { Kato } from './Kato';
import { Oked } from './Oked';
import { Role } from './Role';

export interface UserPhone {
  phone: string;
  isPrefer: boolean;
  isWhatsApp: boolean;
}

export enum UserType {
  INDIVIDUAL = 10,
  ENTITY = 20,
}

export enum UserGender {
  MALE = 10,
  FAMALE = 20,
}

export const UserGenderName: Record<UserGender, string> = {
  [UserGender.MALE]: 'Мужской',
  [UserGender.FAMALE]: 'Женский',
};

export enum UserEmployment {
  FULL = 10,
  PART_TIME = 20,
  DISTANT = 30,
}

export const UserEmploymentNames: Record<UserEmployment, string> = {
  [UserEmployment.FULL]: 'Полная занятость',
  [UserEmployment.PART_TIME]: 'Частичная занятость',
  [UserEmployment.DISTANT]: 'Удаленная работа',
};

export enum UserSchedule {
  FLEXIBLE = 10,
  SHIFT = 20,
  REMOVABLE = 30,
}

export const UserScheduleNames: Record<UserSchedule, string> = {
  [UserSchedule.FLEXIBLE]: 'Гибкий графий',
  [UserSchedule.SHIFT]: 'Вахта',
  [UserSchedule.REMOVABLE]: 'Сменный',
};

export enum UserReadyForBusinessTrips {
  YES = 10,
  NO = 20,
}

export const UserReadyForBusinessTripsNames: Record<
  UserReadyForBusinessTrips,
  string
> = {
  [UserReadyForBusinessTrips.YES]: 'Да',
  [UserReadyForBusinessTrips.NO]: 'Нет',
};

export interface User extends WithCreatedUpdated {
  id: string;
  blocked: boolean;
  basePermissions: string;
  roles: Role[];
  phone: string;
  firstName: string;
  lastName: string;
  middleName: string;
  katoCode: string;
  email: string;
  type: UserType;
  companies: Company[];
  kato: Kato;
  password: string;
  gender: UserGender | null;
  address: string;
  instagram: string | null;
  telegram: string | null;
  employment: UserEmployment | null;
  schedule: UserSchedule | null;
  readyForBusinessTrips: boolean | null;
  additionalInformation: string;
  okedCode: string | null;
  oked: Oked | null;
  phones: string | null;
  webSite: string | null;
  specialization: string | null;
  iin: string | null;
}
