import React, { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '@app/layouts';
import { useUserContext } from '@app/providers';
import { Permission } from '@app/models';
import { getPermissions } from '@app/api';

const PermissionsPage = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    if (!user.authenticated) navigate('/login');

    getPermissions()
      .then((perm) => {
        setPermissions(perm);
      })
      .catch((err) => console.error(err));
  }, [navigate, user.authenticated]);

  return (
    <DefaultLayout>
      <table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {permissions.map(({ id, name }) => (
            <tr key={id}>
              <td>{name}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={() => navigate('/permissions/new/create')}>
        Create new
      </button>
    </DefaultLayout>
  );
};

export default memo(PermissionsPage);
