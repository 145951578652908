import React, { memo } from 'react';
import { DefaultLayout } from '@app/layouts';

function NotFoundPage() {
  return (
    <DefaultLayout>
      <div className="page-404">
        <h1>404</h1>
        <h2>Error: page not found</h2>
      </div>
    </DefaultLayout>
  );
}

export default memo(NotFoundPage);
