import { WithCreatedUpdated } from './Default';
import { Oked } from './Oked';

export enum CompanyTypeNames {
  TOO = 'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ',
  TDO = 'ТОВАРИЩЕСТВА С ДОПОЛНИТЕЛЬНОЙ ОТВЕТСТВЕННОСТЬЮ',
  PT = 'ПОЛНОЕ ТОВАРИЩЕСТВО',
  KT = 'КОММАНДИТНОЕ ТОВАРИЩЕСТВО',
  PK = 'ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ',
  AO = 'АКЦИОНЕРНОЕ ОБЩЕСТВО',
}

export enum CompanyTypes {
  TOO = 10,
  TDO = 20,
  PT = 30,
  KT = 40,
  PK = 50,
  AO = 60,
}

export interface Company extends WithCreatedUpdated {
  id: string;
  blocked: boolean;
  bin: string;
  okedCode: string;
  name: string;
  type: CompanyTypes;
  oked: Oked;
}
