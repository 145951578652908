import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Company } from '@app/models';
import { getCompanyById } from '@app/api';
import { CompanyForm } from '@app/components';
import { DefaultLayout } from '@app/layouts';

const CompanyDetailPage = () => {
  const { id, action } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [comapanyData, setCompanyData] = useState<Partial<Company>>({});

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCompanyById(id)
        .then(({ name, type }) => {
          setLoading(true);
          setCompanyData({ name, type });
        })
        .catch((error) => {
          // setLoading(false); // не имеет большого смысла
          console.error(error);
          navigate('/companies');
        });
    } else {
      navigate('/companies');
    }
  }, [id, navigate]);

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <DefaultLayout>
      <CompanyForm
        data={comapanyData}
        companyId={id as string}
        navigatePath="/companies"
      />
    </DefaultLayout>
  );
};

export default memo(CompanyDetailPage);
