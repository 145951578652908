import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { User } from '@app/models';
import { getBasePermissionsList, getUserById } from '@app/api';
import { UserForm } from '@app/components';
import { DefaultLayout } from '@app/layouts';

const UserDetailPage = () => {
  const { id, action } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<Partial<User>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getUserById(id)
        .then((resData) => {
          setLoading(false);
          setUserData(resData);
        })
        .catch((error) => {
          // setLoading(false); // не имеет большого смысла
          console.error(error);
          navigate('/users');
        });
    } else {
      navigate('/users');
    }
  }, [id, navigate]);

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <DefaultLayout>
      <UserForm data={userData} userId={id as string} navigatePath="/users" />
    </DefaultLayout>
  );
};

export default memo(UserDetailPage);
