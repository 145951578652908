import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllRoles, getBasePermissionsList, updateUser } from '@app/api';
import { Role, User, UserGender } from '@app/models';
import { Form, FormItem } from '..';

interface UserFormProps {
  userId: string;
  navigatePath: string;
  data: User | Partial<User>;
}

const UserForm: FC<UserFormProps> = ({ userId, navigatePath, data }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<Partial<User>>(data);
  const [basePermissions, setBasePermissions] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    try {
      getBasePermissionsList().then((permissions) => {
        setBasePermissions(permissions);
      });
      getAllRoles().then((roles) => {
        setRoles(roles);
      });
    } catch (error) {
      setBasePermissions([]);
      setRoles([]);
    }
  }, []);

  const handlePermissions = useCallback(
    (perm: string) => {
      setBasePermissions((prev) => {
        const newPermissions = [...prev];
        const permIndex = newPermissions.findIndex((p) => p === perm);
        return [
          ...newPermissions.slice(0, permIndex),
          ...newPermissions.slice(permIndex + 1),
        ];
      });
      setUserData((prev) => {
        let { basePermissions: userBasePermissions } = prev;
        const newPerm = basePermissions.find((p) => p === perm);
        if (newPerm) {
          userBasePermissions += `,${newPerm}`;
          return { ...prev, permissions: userBasePermissions };
        }
        return { ...prev };
      });
    },
    [basePermissions]
  );

  const handleClearPermissions = useCallback(() => {
    try {
      getBasePermissionsList().then((list) => {
        setBasePermissions(list);
      });
    } catch (error) {
      return;
    }
    setUserData((prev) => ({ ...prev, basePermissions: '' }));
  }, []);

  const handleRoles = useCallback(
    (roleId: string) => {
      setRoles((prev) => {
        const newRoles = [...prev];
        const roleIndex = newRoles.findIndex((r) => r.id === roleId);
        return [
          ...newRoles.slice(0, roleIndex),
          ...newRoles.slice(roleIndex + 1),
        ];
      });
      setUserData((prev) => {
        let { roles: prevRoles } = prev;
        const newRole = roles.find((r) => r.id === roleId);
        if (newRole) {
          prevRoles?.push(newRole);
          return { ...prev, roles: prevRoles };
        }
        return { ...prev };
      });
    },
    [roles]
  );

  const handleClearRoles = useCallback(() => {
    try {
      getAllRoles().then((list) => {
        setRoles(list);
      });
    } catch (error) {
      return;
    }
    setUserData((prev) => ({ ...prev, roles: [] }));
  }, []);

  const setDataByKey = useCallback((key: keyof User, value: any) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleFormSubmit = useCallback(() => {
    if (userId) updateUser(userId, userData);
  }, [userData, userId]);

  const handleFormCancel = useCallback(() => {
    navigate(navigatePath);
  }, [navigate, navigatePath]);

  // Handlers for items
  const handleFirstName = useCallback(
    (value: string) => setDataByKey('firstName', value),
    [setDataByKey]
  );

  const handleLastName = useCallback(
    (value: string) => setDataByKey('lastName', value),
    [setDataByKey]
  );

  const handleMiddleName = useCallback(
    (value: string) => setDataByKey('middleName', value),
    [setDataByKey]
  );

  const handlePhone = useCallback(
    (value: string) => setDataByKey('phone', value),
    [setDataByKey]
  );

  const handleEmail = useCallback(
    (value: string) => setDataByKey('email', value),
    [setDataByKey]
  );

  const handleMale = useCallback(
    (value: boolean) => {
      setDataByKey('gender', UserGender.MALE);
    },
    [setDataByKey]
  );

  const handleFemale = useCallback(
    (value: boolean) => {
      setDataByKey('gender', UserGender.FAMALE);
    },
    [setDataByKey]
  );

  const handleInst = useCallback(
    (value: string) => setDataByKey('instagram', value),
    [setDataByKey]
  );

  const handleTelegram = useCallback(
    (value: string) => setDataByKey('telegram', value),
    [setDataByKey]
  );

  const handleWebSite = useCallback(
    (value: string) => setDataByKey('webSite', value),
    [setDataByKey]
  );

  const handleAdditionalInfo = useCallback(
    (value: string) => setDataByKey('additionalInformation', value),
    [setDataByKey]
  );

  const handleBlocked = useCallback(
    (value: boolean) => {
      setDataByKey('blocked', value);
    },
    [setDataByKey]
  );

  return (
    <div>
      <Form onSubmit={handleFormSubmit} onCancel={handleFormCancel}>
        <div className="form-group">
          <h2>Common info</h2>
          <FormItem
            label="First name"
            value={userData.firstName}
            onChange={handleFirstName}
          />
          <FormItem
            label="Last name"
            value={userData.lastName}
            onChange={handleLastName}
          />
          <FormItem
            label="Middle name"
            value={userData.middleName}
            onChange={handleMiddleName}
          />
          <div>
            <label>Gender</label>
            <br />
            <FormItem
              label="Male"
              value={userData.gender === UserGender.MALE}
              onChange={handleMale}
              htmlType="checkbox"
            />
            <FormItem
              label="Female"
              value={userData.gender === UserGender.FAMALE}
              onChange={handleFemale}
              htmlType="checkbox"
            />
          </div>
        </div>
        <div className="from-group">
          <h2>Contacts</h2>
          <FormItem
            label="Main phone number"
            value={userData.phone}
            onChange={handlePhone}
          />
          <FormItem
            label="E-mail address"
            value={userData.email}
            onChange={handleEmail}
          />
          <FormItem
            label="Instagram"
            value={userData.instagram}
            onChange={handleInst}
          />
          <FormItem
            label="Telegram"
            value={userData.telegram}
            onChange={handleTelegram}
          />
          <FormItem
            label="Website"
            value={userData.webSite}
            onChange={handleWebSite}
          />
        </div>
        <div className="form-group">
          <h2>Base permissions</h2>
          <div>{userData.basePermissions}</div>
          <FormItem
            label="Base permissions"
            value={basePermissions[0]}
            onChange={handlePermissions}
            onClear={handleClearPermissions}
            htmlType="select"
            options={basePermissions.map((p) => ({ value: p, label: p }))}
          />
        </div>
        <div className="form-group">
          <h2>Roles</h2>
          {userData.roles &&
            userData.roles.map((role) => <div>{role.name}</div>)}
          <FormItem
            label="Roles"
            value={userData?.roles ? userData.roles[0].id : ''}
            onChange={handleRoles}
            onClear={handleClearRoles}
            htmlType="select"
            options={basePermissions.map((p) => ({ value: p, label: p }))}
          />
        </div>
        <div className="form-group">
          <h2>Additional Information</h2>
          <FormItem
            label="Additional information"
            value={userData.additionalInformation}
            onChange={handleAdditionalInfo}
            htmlType="textarea"
          />
        </div>
        <div className="form-group">
          <h2>System</h2>
          <FormItem
            label="Blocked status"
            value={userData.blocked}
            onChange={handleBlocked}
            htmlType="checkbox"
          />
        </div>
      </Form>
    </div>
  );
};

export default memo(UserForm);
