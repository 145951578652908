import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Company } from '@app/models';
import { getCompanies, updateCompany } from '@app/api';
import { useUserContext } from '@app/providers';
import { DefaultLayout } from '@app/layouts';

const CompaniesPage = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    if (!user.authenticated) navigate('/login');

    getCompanies()
      .then((companies) => {
        setCompanies(companies);
      })
      .catch((err) => console.error(err));
  }, [navigate, user.authenticated]);

  const handleToggleBlocked = (id: string, blockedStatus: boolean) => {
    const updatedCompany = { blocked: !blockedStatus };
    updateCompany(id, updatedCompany)
      .then((res) => {
        const updatedUserIndex = companies.findIndex((c) => c.id === id);

        setCompanies((prev) => [
          ...prev.slice(0, updatedUserIndex),
          { ...prev[updatedUserIndex], ...updatedCompany },
          ...prev.slice(updatedUserIndex + 1),
        ]);
      })
      .catch((err) => console.error(err));
  };

  return (
    <DefaultLayout>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {companies.map(({ id, name, type, blocked }) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{type}</td>
              <td>
                <button onClick={() => handleToggleBlocked(id, blocked)}>
                  {blocked ? 'Unblock' : 'Block'}
                </button>
                <button onClick={() => navigate(`/companies/${id}/edit`)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DefaultLayout>
  );
};

export default memo(CompaniesPage);
