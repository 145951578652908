import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultLayout } from '@app/layouts';
import { useUserContext } from '@app/providers';

interface Props {}

function HomePage(props: Props) {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.authenticated) navigate('/login');
  }, [navigate, user.authenticated]);

  return (
    <DefaultLayout>
      <h1>Home page</h1>
    </DefaultLayout>
  );
}

export default memo(HomePage);
