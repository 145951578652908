import React, { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '@app/models';
import { getAllUsers, updateUser } from '@app/api';
import { useUserContext } from '@app/providers';
import { DefaultLayout } from '@app/layouts';

const UsersPage = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!user.authenticated) navigate('/login');

    getAllUsers()
      .then((users) => setUsers(users))
      .catch((err) => console.error(err));
  }, [navigate, user.authenticated]);

  const handleToggleBlocked = (id: string, blockedStatus: boolean) => {
    const updatedUser = { blocked: !blockedStatus };
    updateUser(id, updatedUser)
      .then((res) => {
        const updatedUserIndex = users.findIndex((u) => u.id === id);

        setUsers((prev) => [
          ...prev.slice(0, updatedUserIndex),
          { ...prev[updatedUserIndex], ...updatedUser },
          ...prev.slice(updatedUserIndex + 1),
        ]);
      })
      .catch((err) => console.error(err));
  };

  return (
    <DefaultLayout>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {users.map(
            ({
              id,
              firstName,
              middleName,
              lastName,
              email,
              phone,
              blocked,
            }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{firstName}</td>
                <td>{middleName}</td>
                <td>{lastName}</td>
                <td>{email}</td>
                <td>{phone}</td>
                <td>
                  <button onClick={() => handleToggleBlocked(id, blocked)}>
                    {blocked ? 'Unblock' : 'Block'}
                  </button>
                  <button onClick={() => navigate(`/users/${id}/edit`)}>
                    Edit
                  </button>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </DefaultLayout>
  );
};

export default memo(UsersPage);
