import React, { memo, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { LogoutButton } from '@app/components';
import './styles.scss';

interface DefaultLayoutProps {
  className?: string;
}

function DefaultLayout(props: PropsWithChildren<DefaultLayoutProps>) {
  const { className = '', children } = props;

  return (
    <div className="smr-default-layout">
      {/* <Header /> */}
      <div className="smr-default-layout__aside">
        <ul>
          <li>
            <Link to="/companies">Список компаний</Link>
          </li>
          <li>
            <Link to="/users">Список пользователей</Link>
          </li>
          <li>
            <Link to="/roles">Список ролей</Link>
          </li>
          <li>
            <Link to="/permissions">Список прав</Link>
          </li>
        </ul>
        <div className="smr-default-layout__logout">
          <LogoutButton />
        </div>
      </div>
      <div className={`smr-default-layout__content ${className}`.trim()}>
        {children}
      </div>
    </div>
  );
}

export default memo(DefaultLayout);
