import React, { FC, memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateCompany } from '@app/api';
import { Company } from '@app/models';
import { Form, FormItem } from '..';

interface CompanyFormProps {
  companyId: string;
  navigatePath: string;
  data: Company | Partial<Company>;
}

const CompanyForm: FC<CompanyFormProps> = ({
  companyId,
  navigatePath,
  data,
}) => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState<Partial<Company>>(data);

  const setDataByKey = useCallback((key: keyof Company, value: any) => {
    setCompanyData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleFormSubmit = useCallback(() => {
    if (companyId) updateCompany(companyId, companyData);
  }, [companyData, companyId]);

  const handleFormCancel = useCallback(() => {
    navigate(navigatePath);
  }, [navigate, navigatePath]);

  // Handlers for items
  const handleName = useCallback(
    (value: string) => setDataByKey('name', value),
    [setDataByKey]
  );

  const handleType = useCallback(
    (value: string) => setDataByKey('type', value),
    [setDataByKey]
  );

  const handleBIN = useCallback(
    (value: string) => setDataByKey('bin', value),
    [setDataByKey]
  );

  const handleBlocked = useCallback(
    (value: boolean) => {
      setDataByKey('blocked', value);
    },
    [setDataByKey]
  );

  return (
    <div>
      <Form onSubmit={handleFormSubmit} onCancel={handleFormCancel}>
        <div className="form-group">
          <h2>Common info</h2>
          <FormItem
            label="Name"
            value={companyData.name}
            onChange={handleName}
          />
          <FormItem
            label="Type"
            value={companyData.type}
            onChange={handleType}
          />
          <FormItem label="BIN" value={companyData.bin} onChange={handleBIN} />
        </div>
        <div className="form-group">
          <FormItem
            label="Blocked status"
            value={companyData.blocked}
            onChange={handleBlocked}
            htmlType="checkbox"
          />
        </div>
      </Form>
    </div>
  );
};

export default memo(CompanyForm);
