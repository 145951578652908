import React, { useEffect, useState } from 'react';
import { DefaultLayout } from '@app/layouts';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@app/providers';
import { getAllRoles } from '@app/api';
import { Role } from '@app/models';

const RolesPage = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    if (!user.authenticated) navigate('/login');

    getAllRoles()
      .then((roles) => {
        setRoles(roles);
      })
      .catch((err) => console.error(err));
  }, [navigate, user.authenticated]);

  return (
    <DefaultLayout>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Permissions</th>
            <th>:actions</th>
          </tr>
        </thead>
        <tbody>
          {roles.map(({ id, name, permissions }) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{permissions.map((p) => p.name).join(', ')}</td>
              <td>
                <button onClick={() => navigate(`/roles/${id}/edit`)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={() => navigate('/roles/new/create')}>Create new</button>
    </DefaultLayout>
  );
};

export default RolesPage;
