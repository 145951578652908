import React, { FC, memo, forwardRef } from 'react';

type FormItemType =
  | 'textarea'
  | 'email'
  | 'password'
  | 'tel'
  | 'checkbox'
  | 'radio'
  | 'textarea'
  | 'select';

interface FormItemOption {
  value: string;
  label?: string;
  disabled?: boolean;
}

interface FormItemProps {
  value: any;
  onChange: (value: any) => void;
  onClear?: () => void;
  label?: string;
  htmlId?: string;
  options?: FormItemOption[];
  htmlType?: FormItemType;
  disabled?: boolean;
}

export const FormItem: FC<FormItemProps> = forwardRef(
  (
    {
      onChange,
      onClear,
      value,
      label,
      htmlId,
      options,
      htmlType = 'text',
      disabled = false,
    },
    ref
  ) => {
    const handleChange = (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      onChange(e.target.value);
    };

    const content = () => {
      switch (htmlType) {
        case 'checkbox': {
          console.log(value);
          return (
            <>
              <input
                type="checkbox"
                id={htmlId}
                onChange={handleChange}
                value={value}
                checked={value}
                disabled={disabled}
              />{' '}
              <label htmlFor={htmlId}>{label || ''}</label>
              <button onClick={() => (onClear ? onClear() : onChange(''))}>
                X
              </button>
            </>
          );
        }
        case 'textarea': {
          return (
            <>
              <label htmlFor={htmlId}>{label || ''}</label>
              <br />
              <textarea
                id={htmlId}
                value={value}
                disabled={disabled}
                onChange={handleChange}
              />
              <button onClick={() => (onClear ? onClear() : onChange(''))}>
                X
              </button>
            </>
          );
        }
        case 'select': {
          if (options) {
            return (
              <>
                <label htmlFor={htmlId}>{label || ''}</label>
                <br />
                <select
                  id={htmlId}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                >
                  {options.map(({ value, label, disabled }) => (
                    <option
                      value={value}
                      label={label || value}
                      disabled={!!disabled}
                    >
                      {label}
                    </option>
                  ))}
                </select>{' '}
                <button onClick={() => (onClear ? onClear() : onChange(''))}>
                  X
                </button>
              </>
            );
          } else {
            throw new Error('Give options for select type');
          }
        }
        default: {
          return (
            <>
              <label htmlFor={htmlId}>{label || ''}</label>
              <br />
              <input
                id={htmlId}
                type={htmlType}
                value={value}
                onChange={handleChange}
                disabled={disabled}
              />
              <button onClick={() => (onClear ? onClear() : onChange(''))}>
                X
              </button>
            </>
          );
        }
      }
    };

    return <div className="group__item">{content()}</div>;
  }
);

export default memo(FormItem);
