import React, { useCallback, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormItem } from '@app/components';
import { DefaultLayout } from '@app/layouts';
import { Permission } from '@app/models';
import { createPermission } from 'api/premission';

const PermissionsDetailPage = () => {
  const navigate = useNavigate();

  const [permission, setPermission] = useState<Partial<Permission>>({});

  const handleCancel = useCallback(() => {
    navigate('/permissions');
  }, [navigate]);

  const handleChangeName = useCallback((name: string) => {
    setPermission((prev) => ({ ...prev, name }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await createPermission(permission);
    } catch (err) {
      console.error(err);
    }
  }, [permission]);

  return (
    <DefaultLayout>
      <h1>Create permission</h1>
      <Form onSubmit={handleSubmit} onCancel={handleCancel}>
        <FormItem
          value={permission.name}
          onChange={handleChangeName}
          label="Name"
        />
      </Form>
    </DefaultLayout>
  );
};

export default memo(PermissionsDetailPage);
