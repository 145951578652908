import { CreateUpdateRole, Role } from 'models/Role';
import instance from './instance';

export async function getAllRoles(): Promise<Role[]> {
  const res = await instance().get('/roles/getAll');
  return res.data;
}

export async function getRoleById(id: string): Promise<Role> {
  const res = await instance().get('/roles/getById/' + id);
  return res.data;
}

export async function createRole(
  data: Partial<CreateUpdateRole>
): Promise<Role> {
  const res = await instance().post('/roles/create', data);
  return res.data;
}

export async function updateRole(
  id: string,
  data: Partial<CreateUpdateRole>
): Promise<Role> {
  const res = await instance().patch('/roles/update' + id, data);
  return res.data;
}

export async function updateRolePermissions(
  id: string,
  data: string[]
): Promise<Role> {
  const res = await instance().patch(`/roles/update/${id}/permissions`, data);
  return res.data;
}
