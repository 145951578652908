import React, { memo, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  HomePage,
  NotFoundPage,
  LoginPage,
  CompaniesPage,
  UsersPage,
  UserDetailPage,
  CompanyDetailPage,
  RolesPage,
  PermissionsPage,
  PermissionsDetailPage,
  RolesDetailPage,
} from '@app/pages';
import { useUserContext } from '@app/providers';
import { getUserById } from '@app/api';
import { parseJwt } from './helpers';

function Router() {
  const { setUser } = useUserContext();

  useEffect(() => {
    (async () => {
      const accessToken = Cookies.get('accessToken');

      if (!accessToken) {
        return;
      }

      const userResponse = await getUserById(parseJwt(accessToken).id);

      setUser({
        authenticated: !!accessToken,
        ...userResponse,
      });
    })();
  }, [setUser]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route index element={<></>} />
      </Route>
      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/:id/:action" element={<UserDetailPage />} />
      {/* {user.authenticated && ( */}
      <Route path="/companies" element={<CompaniesPage />} />
      <Route path="/companies/:id/:action" element={<CompanyDetailPage />} />
      {/*);}*/}
      <Route path="roles" element={<RolesPage />} />
      <Route path="roles/:id/:action" element={<RolesDetailPage />} />
      {/**/}
      <Route path="permissions" element={<PermissionsPage />} />
      <Route
        path="permissions/:id/:action"
        element={<PermissionsDetailPage />}
      />
      {/**/}
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default memo(Router);
