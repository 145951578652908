import React, { FC, ReactNode, memo } from 'react';

interface FormProps {
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  onCancel: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

const Form: FC<FormProps> = ({ onSubmit, onCancel, children }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      onSubmit(e);
    } catch (error) {
      console.log(error);
      onCancel();
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      onCancel(e);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form-wrapper">
      <form onSubmit={handleSubmit} className="form">
        {children}
      </form>

      <div className="form__button-group">
        <button type="submit">Save</button>
        <button onClick={handleCancel} type="button">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default memo(Form);
