import { format, formatDistance } from 'date-fns';
import { memo } from 'react';
import { CompanyTypeNames, CompanyTypes, User } from '@app/models';
import ru from 'date-fns/locale/ru';

export type ValueOf<T> = T[keyof T];

export type KeyOf<T> = keyof T;

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function stringToDate(date: string) {
  const dateParts = date.split('.');

  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]),
    Number(dateParts[0])
  );
}

export function setMask(value: string, mask: string): string {
  let i = 0;
  let lastReplacedIndex = -1;

  const filledMask = mask.replace(/#/g, (_, j) => {
    if (i >= value.length) {
      return '#';
    }
    lastReplacedIndex = j;
    return value[i++];
  });

  return filledMask.substring(0, lastReplacedIndex + 1);
}

export function dateFormat(
  date: string | null | undefined = null,
  formatType: string = 'dd.MM.yyyy'
) {
  try {
    if (!date) {
      return null;
    }
    return format(new Date(date), formatType);
  } catch {
    console.log('parsing or invalid date');
    return '';
  }
}

export function moneyFormat(value: string | null | number | undefined) {
  const moneyValue = value || '';
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const parts = moneyValue.toString().split('.');
  const numberPart = parts[0];
  let decimalPart;
  if (parts.length > 0) {
    decimalPart = parts[1];
  }
  return (
    numberPart.replace(thousands, ' ') + (decimalPart ? '.' + decimalPart : '')
  );
}

export function onlyDigit(value: string | null | number | undefined) {
  const digitValue = value || '';
  return digitValue?.toString().replace(/[^\d]/g, '');
}

export const genericMemo: <T>(component: T) => T = memo;

export const convertToDate = (dateString: string) => {
  let d = dateString.split('.');

  return new Date(d[2] + '.' + d[1] + '.' + d[0]);
};

export const urlUpdateParams = (params: {
  [key: string]: string | number | undefined;
}) => {
  const url = new URL(`${window.location.origin}${window.location.pathname}`);

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (typeof value !== 'undefined' && value !== null) {
      url.searchParams.set(
        key,
        typeof value === 'string' ? value : value.toString()
      );
    }
  });

  window.history.replaceState(null, 'React App', url);
};

export const getUrlParam = (key: string): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return urlSearchParams.get(key);
};

export function shotName(
  user: Pick<User, 'lastName' | 'firstName' | 'middleName'>
): string {
  let fullName = user.lastName;

  if (!!user.firstName) {
    fullName += ` ${user.firstName.charAt(0)}`;
  }

  if (!!user.middleName) {
    fullName += `.${user.middleName.charAt(0)}`;
  }

  return fullName;
}

export function parseFullName(
  fullName: string
): Pick<User, 'firstName' | 'lastName' | 'middleName'> {
  const fullNameArray = fullName.split(' ');

  return {
    firstName: fullNameArray[1] || '',
    lastName: fullNameArray[0] || '',
    middleName: fullNameArray[2] || '',
  };
}

export function getCompanyTypeByName(name: CompanyTypeNames): CompanyTypes {
  switch (name) {
    case CompanyTypeNames.TDO:
      return CompanyTypes.TDO;
    case CompanyTypeNames.PT:
      return CompanyTypes.PT;
    case CompanyTypeNames.KT:
      return CompanyTypes.KT;
    case CompanyTypeNames.PK:
      return CompanyTypes.PK;
    case CompanyTypeNames.AO:
      return CompanyTypes.AO;
    default:
      return CompanyTypes.TOO;
  }
}

export function getCompanyTypeName(type: CompanyTypes): string {
  switch (type) {
    case CompanyTypes.TOO:
      return 'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ';
    case CompanyTypes.TDO:
      return 'ТОВАРИЩЕСТВА С ДОПОЛНИТЕЛЬНОЙ ОТВЕТСТВЕННОСТЬЮ';
    case CompanyTypes.PT:
      return 'ПОЛНОЕ ТОВАРИЩЕСТВО';
    case CompanyTypes.KT:
      return 'КОММАНДИТНОЕ ТОВАРИЩЕСТВО';
    case CompanyTypes.PK:
      return 'ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ';
    case CompanyTypes.AO:
      return 'АКЦИОНЕРНОЕ ОБЩЕСТВО';
    default:
      return '';
  }
}

export function getCompanyTypeShortName(type: CompanyTypes): string {
  switch (type) {
    case CompanyTypes.TOO:
      return 'ТОО';
    case CompanyTypes.TDO:
      return 'ТДО';
    case CompanyTypes.PT:
      return 'ПТ';
    case CompanyTypes.KT:
      return 'КТ';
    case CompanyTypes.PK:
      return 'ПК';
    case CompanyTypes.AO:
      return 'АО';
    default:
      return '';
  }
}

export function capitalize(string: string): string {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
  } catch (e) {
    return '';
  }
}

export const generateUniqueId = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export function getFormattedDate(date: string): string {
  try {
    return format(new Date(date), 'dd.MM.yyyy');
  } catch (e) {
    return '';
  }
}

export function getFileExtension(file: File): string {
  const fileNameParts = file.name.split('.');

  return fileNameParts[fileNameParts.length - 1];
}

export function splitFiles(files: File[]): {
  files: File[];
  images: File[];
} {
  return {
    files: files.filter(
      (file) => getFileExtension(file).toLocaleLowerCase() === 'pdf'
    ),
    images: files.filter(
      (file) => getFileExtension(file).toLocaleLowerCase() !== 'pdf'
    ),
  };
}

export function formatEndDate(date: string) {
  return formatDistance(new Date(), new Date(date), {
    locale: ru,
  });
}

export function currencyFormat(value: string | number) {
  const format = new Intl.NumberFormat('ru-RU').format(+value);

  return `${format} ₸`;
}

export function mapRecordToSelectOption(data: any, data2: any) {
  return {
    label: data2[data],
    value: data,
  };
}
