import { Permission } from 'models/Permission';
import instance from './instance';

export async function getPermissions(): Promise<Permission[]> {
  const res = await instance().get('/permissions/getAll');
  return res.data;
}

export async function getBasePermissionsList(): Promise<string[]> {
  const res = await instance().get('/permissions/getAllBase');
  return res.data;
}

export async function getPermissionById(id: string): Promise<Permission> {
  const res = await instance().get('/permissions/getById/' + id);
  return res.data;
}

export async function createPermission(
  data: Partial<Permission>
): Promise<Permission> {
  const res = await instance().post('permissions/create', data);
  return res.data;
}
