import { Company } from './Company';
import { WithCreatedUpdated } from './Default';

export enum DocTypes {
  CONTRACT = 10,
  ACT = 20,
  INVOICE = 30,
}

export enum DocStatus {
  DEFAULT = 10,
  OWNER_SIGNED = 20,
  RECIPIENT_SIGNED = 30,
  SIGNED = 40,
}

export interface Doc extends WithCreatedUpdated {
  id: string;
  ownerBin: string;
  recipientBin: string;
  name: string;
  description: string;
  price: string;
  contractId?: string;
  type: DocTypes;
  recipient: Company;
  owner: Company;
  filesIds: string;
  status: DocStatus;
  docs?: Doc[];
}
