import React, { FC, memo } from 'react';
import Cookies from 'js-cookie';

import { useUserContext } from '@app/providers';

const LogoutButton: FC = () => {
  const { user, setUser } = useUserContext();

  const handleLogoutClick = () => {
    Cookies.set('accessToken', '');

    setUser({ ...user, authenticated: false });
  };

  return <button onClick={handleLogoutClick}>Logout</button>;
};

export default memo(LogoutButton);
