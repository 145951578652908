import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';

import { SignInData, signIn } from '@app/api';
import { useUserContext } from '@app/providers';
import { parseJwt } from '@app/helpers';

const LoginPage = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (user.authenticated) navigate(-1);
  }, [navigate, user.authenticated]);

  const onSubmit = (data: SignInData): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (user.blocked) throw new Error(`User ${user.firstName} is blocked`);

        const signInResponse = await signIn(data);

        console.log('signInresponse', signInResponse);

        setTimeout(() => {
          Cookies.set('accessToken', signInResponse.accessToken);

          setUser({
            authenticated: true,
            ...parseJwt(signInResponse.accessToken),
          });

          resolve(true);

          navigate('/');
        }, 500);
      } catch (e) {
        setTimeout(() => {
          reject(e);
        }, 500);
      }
    });
  };

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const data: SignInData = {
        phone,
        password,
      };

      await onSubmit(data);
    } catch (e) {
      const error = e as AxiosError<{
        message: string;
      }>;
      setErrorMsg(error.message);

      console.error(error.message);
    }
  };

  return (
    <>
      <form onSubmit={onSubmitForm}>
        <div>
          <label htmlFor="admin-login">Login</label>
          <br />
          <input
            id="admin-login"
            type="text"
            placeholder="Enter login..."
            value={phone}
            onChange={onChangePhone}
          />
        </div>
        <div>
          <label htmlFor="admin-password">Password</label>
          <br />
          <input
            type="password"
            name="admin-password"
            id="admin-password"
            placeholder="Enter password..."
            value={password}
            onChange={onChangePassword}
          />
        </div>
        <button>Login</button>
      </form>

      <span style={{ color: 'red' }}>{errorMsg}</span>
    </>
  );
};

export default memo(LoginPage);
