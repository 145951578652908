import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormItem } from '@app/components';
import { DefaultLayout } from '@app/layouts';
import { Permission, Role } from '@app/models';
import { createRole, getPermissions, getRoleById, updateRole } from '@app/api';

const RolesDetailPage = () => {
  const { id, action } = useParams();
  const navigate = useNavigate();

  const [role, setRole] = useState<Partial<Role>>({});
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    try {
      getPermissions().then((list) => {
        console.log(list);
        setPermissions(list);
      });
      if (id && (action === 'edit' || action === 'read')) {
        getRoleById(id)
          .then((role) => {
            setRole(role);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [action, id]);

  const handleSelect = useCallback(
    (permId: string) => {
      setPermissions((prev) => {
        const newPermissions = [...prev];
        const permIndex = newPermissions.findIndex(
          (perm) => perm.id === permId
        );
        return [
          ...newPermissions.slice(0, permIndex),
          ...newPermissions.slice(permIndex + 1),
        ];
      });
      setRole((prev) => {
        const { permissions: prevPerms } = prev;
        const newPerm = permissions.find((perm) => perm.id === permId);
        if (newPerm) {
          prevPerms?.push(newPerm);
          return { ...prev, permissions: prevPerms };
        }
        return { ...prev };
      });
    },
    [permissions]
  );

  const handleClearSelect = useCallback(() => {
    try {
      getPermissions().then((list) => {
        setPermissions(list);
      });
    } catch (error) {
      return;
    }
    setRole((prev) => ({ ...prev, permissions: [] }));
  }, []);

  const handleCancel = useCallback(() => {
    navigate('/roles');
  }, [navigate]);

  const handleChangeName = useCallback((name: string) => {
    setRole((prev) => ({ ...prev, name }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (action === 'create') {
        await createRole(role);
      }
      if (id && action === 'edit') {
        await updateRole(id, role);
      }
    } catch (err) {
      console.error(err);
    }
  }, [action, id, role]);

  return (
    <DefaultLayout>
      <h1>
        {action === 'create' && 'Create New Role'}
        {action === 'edit' && 'Edit Role'}
        {action === 'read' && 'Role'}
      </h1>
      <Form onSubmit={handleSubmit} onCancel={handleCancel}>
        <FormItem
          onChange={handleChangeName}
          label="Name"
          value={role.name}
          disabled={action === 'read'}
        />
        <div className="form-group">
          {role.permissions &&
            role.permissions.map((permission) => (
              <>
                <span>{permission.name}</span>
                <br />
              </>
            ))}
          <FormItem
            label="Permissions list"
            disabled={!permissions.length || action === 'view'}
            value={permissions[0]?.id || ''}
            onChange={handleSelect}
            onClear={handleClearSelect}
            htmlType="select"
            options={permissions.map((permission) => ({
              value: permission.id,
              label: permission.name,
            }))}
          />
        </div>
      </Form>
    </DefaultLayout>
  );
};

export default memo(RolesDetailPage);
