import instance from './instance';
import { Company } from '../models';
// import { PageableResponse } from './responses';
// import { PageableParams } from './params';

export interface CreateCompanyData
  extends Omit<Company, 'id' | 'createdAt' | 'updatedAt'> {}

export function createCompany(data: CreateCompanyData): Promise<Company> {
  return instance()
    .post('/companies/create', data)
    .then((response) => response.data);
}

export function updateCompany(
  id: string,
  data: Partial<Company>
): Promise<Company> {
  return instance()
    .patch(`/companies/${id}`, data)
    .then((response) => response.data);
}

export function getCompanies(): Promise<Company[]> {
  return instance()
    .get('/companies/getAll')
    .then((response) => response.data.data);
}

export function getCompanyById(companyId: string): Promise<Company> {
  return instance()
    .get(`/companies/getById/${companyId}`)
    .then((response) => response.data);
}

export function getCompanyByBin(companyBim: string): Promise<Partial<Company>> {
  return instance()
    .get(`/companies/getByBin/${companyBim}`)
    .then((response) => response.data);
}
