import instance from './instance';
import { Company, User } from '../models';
import { PageableResponse, SignInResponse } from './responses';
import { PageableParams } from './params';

export interface SignInData extends Pick<User, 'phone'> {
  password: string;
}

export interface SignUpData {
  user: SignInData & Partial<User>;
  company?: Partial<Company> | null;
}

export function signIn(data: SignInData): Promise<SignInResponse> {
  return instance(false)
    .post('/auth/signIn', data)
    .then((response) => response.data);
}

export function signUp(data: SignUpData): Promise<SignInResponse> {
  return instance(false)
    .post('/auth/signUp', data)
    .then((response) => response.data);
}

export function getAllUsers(): Promise<User[]> {
  return instance()
    .get('/users/getAll')
    .then((response) => response.data.data);
}

export function getUserById(id: string): Promise<User> {
  return instance()
    .get(`/users/getById/${id}`)
    .then((response) => response.data);
}

export function updateUser(id: string, data: Partial<User>): Promise<User> {
  return instance()
    .patch(`/users/${id}`, data)
    .then((response) => response.data);
}

export function checkUserExists(email: string): Promise<boolean> {
  return instance().get('/auth/checkUserExists', {
    params: {
      email,
    },
  });
}

export function getPartners(
  params: PageableParams & Partial<Company>
): Promise<PageableResponse<User>> {
  return instance()
    .get('/users/partners', {
      params,
    })
    .then((response) => response.data);
}
