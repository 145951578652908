import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Company, User, UserType } from '@app/models';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface Authenticated {
  authenticated: boolean;
}

interface StoredUser extends Authenticated, User {}

interface UserContextValue {
  user: StoredUser;
  setUser: (user: StoredUser) => void;
  logout: () => void;
  company: Company | null;
}

const UserContext = createContext<UserContextValue>({} as UserContextValue);

function UserProvider(props: PropsWithChildren) {
  const { children } = props;
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<StoredUser>({
    authenticated: false,
  } as StoredUser);

  const setUserData = useCallback((data: StoredUser) => {
    if (data.type === UserType.ENTITY) {
      setCompany(data.companies[0]);
    }

    setUser({
      ...data,
    });
  }, []);

  const logout = useCallback(() => {
    Cookies.remove('accessToken');

    setUser({
      authenticated: false,
    } as StoredUser);

    navigate('/');
  }, [navigate]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: setUserData,
        logout,
        company,
      }}
    >
      <>{children}</>
    </UserContext.Provider>
  );
}

export function useUserContext(): UserContextValue {
  return useContext(UserContext);
}

export { UserProvider, UserContext };
